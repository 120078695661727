@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.portal {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    .menu {
      color: #ffffff;
    }
    .navigation-buttons {
      flex-grow: 1;
      .navigation-button {
        color: #ffffff;
        .padding {
          height: 24px;
          width: 12px;
        }
        .icon {
          width: 24px;
          height: 24px;
        }
        .title {
          margin-left: 10px;
        }
        &.here {
          border-bottom: 3px solid #ffffff;
          border-radius: 0;
        }
      }
    }
    .login {
      color: #ffffff;
    }
  }

  .purchases-container {
    flex-grow: 1;
    padding-bottom: 30px;
    overflow: auto;

    .purchase-container {
      animation: bounce-in 0.5s;
      animation-fill-mode: forwards;
      margin: 30px 0;

      .purchase {
        margin: 0;
      }

      .extend-time-select-control {
        min-width: 120px;
        margin-top: -16px;
      }
    }

    .create-run-button {
      border: 2px solid #000000;
      border-radius: 10px;
      padding: 4px 10px;
    }

    .runs-expander {
      margin-top: 5px !important;
    }

    .runs-expander-details {
      margin: 0;
      padding: 0;
    }

    .exam-runs-header {
      height: 48px;
      min-height: 48px;
      .one-third {
        flex-basis: 33.33%;
      }
      .secondary {
        color: #0000008a;
      }
    }

    .run-launch {
      border: 1px solid #000000;
      border-radius: 10px;
      padding: 4px 10px;
    }

    .run-results {
      border: 1px solid #000000;
      border-radius: 10px;
      padding: 4px 10px;
      margin-right: 10px;
    }

    .one-quarter {
      width: 25%;
    }
  }

  .marketplace {
    flex-grow: 1;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow: auto;

    .full-size {
      height: 100%;
    }

    .centered {
      margin: auto;
    }

    .product {
      overflow: visible;
      animation: bounce-in 0.2s;
      animation-fill-mode: forwards;
    }

    .product-image {
      height: 150px;
    }

    .purchase-button {
      border: 2px solid #000000;
      border-radius: 10px;
      padding: 4px 10px;
      background: transparent;
      cursor: pointer;

      &.purchased {
        border: none;
        font-weight: normal;
        padding: 6px 10px;
      }
    }

    .price-box {
      display: contents;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex-grow: 1;
    }

    .purchased-label-wrapper {
      position: relative;
      animation: bounce-in 0.5s;
      user-select: none;
      .purchased-label {
        display: block;
        color: #ff0000;
        font-size: large;
        font-family: fantasy;
        border: 4px solid #ff0000;
        border-radius: 4px;
        padding: 3px;
        transform: rotate(-20deg);
      }
    }
  }

  .admin {
    flex-grow: 1;
    overflow: auto;

    .new-product-container {
      margin: 10px 0;
    }

    .upload-exam-container {
      display: flex;
      flex-direction: row;
      margin: 10px 0;

      .upload-label,
      .upload-status {
        margin: auto 10px;
      }

      .upload-status {
        flex-grow: 1;
      }
    }

    .product-def-logo {
      max-height: 75px;
      max-width: 75px;
      margin: auto;
    }

    .logo-edit-holder {
      display: flex;
      flex-direction: column;
    }

    .product-def-edit-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .page-number-button {
    border: 1px solid #000000;
    border-radius: 10px;
    min-width: 50px;
    width: 50px;

    &.first,
    &.last {
      min-width: 75px;
      width: 75px;
    }

    &.selected {
      background: #f8b332;
    }
  }

  .query-results {
    margin: 10px auto auto;
    flex-grow: 1;
    overflow: auto;

    .card-grid {
      margin-top: 0px;
    }
  }

  .query-pagination {
    margin: 10px auto;
  }

  .item-card-label-column {
    width: 30%;
  }

  .upload-content-container {
    display: flex;
    flex-direction: row;
    margin: 10px 0;

    .upload-label,
    .upload-status {
      margin: auto 10px;
    }

    .upload-status {
      flex-grow: 1;
    }
  }

  .user-button-and-menu {
    color: #ffffff;

    .email {
      color: #ffffff;
      padding-left: 10px;
    }

    .login-button {
      color: #ffffff;
    }
  }
}

.extracted-drawer-header {
  display: flex;
  flex-direction: row;
}

.extracted-drawer-handle {
  border-radius: 0 !important;

  &.inner {
    flex-grow: 1;
  }
}

.extracted-drawer {
  max-height: 50% !important;
}

.upload-content-format {
  max-height: 50% !important;
  max-width: 50% !important;
  overflow: hidden !important;
  padding: 30px 0 !important;

  .format-container {
    padding: 15px 30px;
    overflow: auto;
  }
}

.no-hover-highlight {
  &:hover {
    background-color: transparent !important;
  }
}
