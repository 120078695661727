@font-face {
  font-family: "aamc-icons";
  src: local("aamc-icons"), url("../fonts/aamc-icons.woff") format("woff");
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

#root {
  height: 100%;
}

span {
  margin: 0;
  padding: 0;
}

.examination-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  line-height: 1.618em;

  .header {
    height: 45px;
    min-height: 45px;
    background: #006daa;
    color: #ffffff;
    font-family: Tahoma, Geneva, sans-serif;
  }

  .title {
    line-height: 40px;
    padding-left: 20px;
    color: #ffffff;
    font-size: 16pt;
    float: left;
    font-weight: normal;
  }

  .time-remaining {
    display: inline;
    position: absolute;
    right: 20px;
    top: 1px;
    font-size: 11pt;
    cursor: pointer;
  }

  .time-remaining:hover {
    color: #ffff00;
  }

  .time-remaining .icon::before {
    content: "r";
    font-family: "aamc-icons";
    vertical-align: bottom;
    font-size: 12pt;
  }

  .time-remaining .text {
    display: inline-block;
    margin-left: 3px;
  }

  .time-remaining .time {
    display: inline-block;
    margin-left: 3px;
  }

  .question-counter {
    display: inline;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 11pt;
    cursor: pointer;
  }

  .question-counter:hover {
    color: #ffff00;
  }

  .question-counter .icon::before {
    content: "a";
    font-size: 12pt;
    vertical-align: bottom;
    font-family: "aamc-icons";
  }

  .question-counter .text {
    margin-left: 3px;
  }

  .divider {
    border: 1px solid #ffffff;
    margin: 0;
    padding: 0;
  }

  .subheader {
    height: 30px;
    min-height: 30px;
    background: #80aee1;
    color: #ffffff;
    padding: 0 20px;
    user-select: none;
  }

  .subheader .flag {
    line-height: 28px;
    font-family: Verdana;
    cursor: pointer;
  }

  .subheader .flag:hover {
    color: #ffff00;
  }

  .subheader .flag::before {
    position: relative;
    right: 5px;
    top: 1px;
    content: "g";
    font-family: "aamc-icons";
  }

  .subheader .flag.flagged {
    color: #ffff00;
  }

  .subheader .flag.flagged::before {
    content: "f";
  }

  .subheader .highlight {
    line-height: 28px;
    font-family: Verdana;
    cursor: pointer;
    padding: 0 10px 0 0;
    margin-top: 2px;
  }

  .subheader .highlight-dropdown-icon-and-arrow::after {
    content: "▼";
    color: #ffffff;
    font-size: 9pt;
    position: relative;
    top: 0px;
    padding-right: 1px;
  }

  .subheader .highlight-icon {
    width: 16px;
    height: 16px;
    top: 3px;
    border: 2px solid white;
    display: inline-block;
    position: relative;
    margin-right: 3px;
  }

  .subheader .highlight-icon.yellow {
    background-color: #ffff00;
  }

  .subheader .highlight-dropdown {
    background-color: #006daa;
    z-index: 999;
    position: absolute;
    left: 18px;
    border: 1px solid black;
    top: 79px;
    display: flex;
    flex-direction: column;
    padding-top: 4px;
    font-family: Verdana;
    font-size: 16px;
  }

  .subheader .highlight-dropdown .highlight-icon {
    border: none;
  }

  .subheader .highlight-dropdown .highlight-icon.clear {
    height: 20px;
    width: 20px;
    border: 2px solid #ffffff;
  }

  .subheader .highlight-dropdown-row {
    padding: 0 6px 6px;
    display: flex;
    flex-direction: row;
  }

  .subheader .highlight-dropdown-row.final {
    padding-top: 1px;
  }

  .subheader .highlight-dropdown-row.final .label {
    padding: 0 20px 0 5px;
  }

  .subheader .highlight-dropdown-row .highlight-icon {
    width: 23px;
    height: 23px;
  }

  .subheader .strikethrough {
    line-height: 28px;
    font-family: Verdana;
    cursor: pointer;
    padding-left: 12px;
    margin-top: 1px;
  }

  .subheader .strikethrough::before {
    position: relative;
    right: 4px;
    top: 1px;
    content: "n";
    font-family: "aamc-icons";
  }

  .pause-bar {
    height: 28px;
    min-height: 28px;
    background: #a6a6a6;
    user-select: none;
    text-align: center;
  }

  .pause-link {
    background: #f4f4f4;
    position: relative;
    top: 2px;
    border: 1px solid #ffffff;
    cursor: pointer;
    padding: 0 10px;
    font-size: 12pt;
    font-family: Verdana, Geneva, sans-serif;
    text-decoration: none;
  }

  .content-container {
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    line-height: initial;
  }

  .content {
    flex-grow: 1;
  }

  .content-container.split .content {
    width: calc(50% - 25px);
  }

  .splitter {
    width: 25px;
    background: #006daa;
    user-select: none;
  }

  .scrollable {
    overflow: auto;
  }

  .scrollable::-webkit-scrollbar {
    width: 18px;
    height: 18px;
  }

  .scrollable::-webkit-scrollbar-thumb {
    background: #80aee1;
  }

  .scrollable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  .scrollable::-webkit-scrollbar-button:start:decrement,
  .scrollable::-webkit-scrollbar-button:end:increment {
    height: 18px;
    width: 18px;
    display: block;
    background-color: #80aee1;
  }

  .scrollable::-webkit-scrollbar-button:end:decrement,
  .scrollable::-webkit-scrollbar-button:start:increment {
    display: none;
  }

  .scrollable::-webkit-scrollbar-button:vertical:increment {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4gcTDx43AHTppQAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAAAm0lEQVQ4y+2Suw3CQBBE39xdjoREE27FvVABIR1QAEW4EIhcByHB2UPAIVlgI+wMySNNsp+3u9LCqv+TxoNhj9hg8qDGiIS5mf703pNGQWIn4gH5Y4TVHe0ZqwbFa1RyVLoXOyheFtysuoBysYXquZxYtmoKzEGxGeZmgUDVCwSqvoHSBKh75tya/gwY3Jb6vPQ1tsWT77LqNz0Anmsn5gIKn8AAAAAASUVORK5CYII=);
    background-size: cover;
    background-repeat: no-repeat;
    border-top: 1px solid #eee;
  }

  .scrollable::-webkit-scrollbar-button:vertical:decrement {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAAlwSFlzAAALEwAACxMBAJqcGAAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KTMInWQAAAKJJREFUOBHtUcENwjAQu0vSBRijq3QnPswBSzAI7MGnzz5Kgx2cX4QieCE1kuvcxbbSi9m+fpmAy3wAE1y19646v4m6EMIFOMtTep3+IosSj9FTJlCP6tUzlZ+piN3jtQZx/00QPZNCVjDBW00K66fg4a6gBUxk9G79CVBiuKfoQ5mNwrQfMs9aYc1X2DZ7mD2PMKxAfXL+WrJscyto7/3jBF4VyyFYXSX8JQAAAABJRU5ErkJggg==);
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom: 1px solid #eee;
  }

  .scrollable::-webkit-scrollbar-button:horizontal:increment {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAAsTAAALEwEAmpwYAAABWWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpMwidZAAAAvElEQVQ4Ee1ROw7CMAy1nSJ2jsHSg1BmbsHKxNR7cAl6DaQusHASpCbuM1KkKC2RmGDokyzZsd/zJ0QL/uECchSRNpnEwTf7FnJ2vFJh6cHcJewKPifx25X8IYkHIgVDasdVx+yuyG1heEciQ0kodn2B44W4geAd614QbzIdKgnltTGeTGMJ2/cTImFtBYG0U/UnLPWYI5QmQhMGL/RehwYiewiYyOyxC9/JNYvegoYDyE+Y1VpjD1vwywuMc2QovmDQf+cAAAAASUVORK5CYII=");
    background-size: cover;
    background-repeat: no-repeat;
    border-right: 1px solid #eee;
  }

  .scrollable::-webkit-scrollbar-button:horizontal:decrement {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAAsTAAALEwEAmpwYAAABWWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpMwidZAAAAyUlEQVQ4Ee1Ruw3CQAy1fUH0jAFsQlgie1BRIRZgEJaABtEgsUQmSO7FzkfKSQ6iSpUnOXf+vdjviBbMpkD48SfLiRqsRkTOAO/VfZr/D1iLslHhQVhegVdKKKdRPLmOG4aETVCpbZnDRYjzLtEOZnEXHtFG17gypOg7aj2NYK1m07owDTxMNnjFFvOIyhhjUaPaRcJda0x0m8bQ7tdd069HNIj9AeqjEuag+O638qRoGaeeP2p2eP4vCDcWygAqdahHOsviza5AA+J9L9WtRJHRAAAAAElFTkSuQmCC");
    background-size: cover;
    background-repeat: no-repeat;
    border-left: 1px solid #eee;
  }

  .nav-bar {
    background: #006daa;
    color: #ffffff;
    padding: 0 10px;
    height: 40px;
  }

  .nav-button {
    background: none;
    border: none;
    height: 30px;
    line-height: 30px;
    color: #ffffff;
    padding: 0 10px;
    margin: 5px 0;
    font-size: 14pt;
    font-family: Tahoma, Geneva, sans-serif;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    position: relative;
    user-select: none;
  }

  .nav-button:hover {
    color: #ffff00;
  }

  .nav-button.end-exam {
    border-right: 1px solid #ffffff;
    padding-left: 25px;
    position: relative;
  }

  .nav-button.end-exam::after {
    position: absolute;
    left: 0;
    content: "w";
    font-family: "aamc-icons";
  }

  .nav-button.end-section {
    border-right: 1px solid #ffffff;
    padding-left: 25px;
    position: relative;
  }

  .nav-button.end-section::after {
    position: absolute;
    left: 0;
    content: "w";
    font-family: "aamc-icons";
  }

  .nav-button.periodic-table {
    border-right: 1px solid #ffffff;
    padding-left: 25px;
    position: relative;
  }

  .nav-button.periodic-table::before {
    position: absolute;
    left: 0;
    content: "v";
    font-family: "aamc-icons";
  }

  .nav-button.previous {
    border-left: 1px solid #ffffff;
    padding-left: 30px;
    position: relative;
  }

  .nav-button.previous::after {
    position: absolute;
    left: 5px;
    content: "u";
    font-family: "aamc-icons";
  }

  .nav-button.navigation {
    border-left: 1px solid #ffffff;
    padding-left: 30px;
    position: relative;
  }

  .nav-button.navigation::after {
    position: absolute;
    left: 5px;
    content: "l";
    font-family: "aamc-icons";
  }

  .nav-button.next {
    border-left: 1px solid #ffffff;
    padding-right: 25px;
  }

  .nav-button.next::after {
    content: "m";
    font-family: "aamc-icons";
    position: absolute;
    right: 0;
  }

  .nav-button.review-all {
    border-left: 1px solid #ffffff;
    padding-left: 27px;
  }

  .nav-button.review-all::after {
    content: "p";
    font-family: "aamc-icons";
    position: absolute;
    left: 3px;
  }

  .nav-button.review-incomplete {
    border-left: 1px solid #ffffff;
    padding-left: 27px;
  }

  .nav-button.review-incomplete::after {
    content: "h";
    font-family: "aamc-icons";
    position: absolute;
    left: 3px;
  }

  .nav-button.review-flagged {
    border-left: 1px solid #ffffff;
    padding-left: 27px;
  }

  .nav-button.review-flagged::after {
    content: "f";
    font-family: "aamc-icons";
    position: absolute;
    left: 3px;
  }

  .dialog {
    border: 1px solid #000000;
    background-color: #006daa;
    color: #ffffff;
    font-family: Tahoma, Geneva, sans-serif;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 100;
  }

  .modal-backdrop {
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: all;
  }

  .modal-backdrop.closed {
    display: none;
  }

  .dialog.closed {
    display: none;
  }

  .dialog .close {
    cursor: pointer;
  }

  .dialog .close:hover {
    color: #ffff00;
  }

  .dialog .scrollable {
    overflow-x: hidden;
  }

  .dialog .dialog-header {
    padding: 0px 10px;
    border-bottom: 1px solid #ffffff;
    height: 28px;
    cursor: move;
    user-select: none;
    display: flex;
  }

  .dialog .dialog-header .dragable {
    flex-grow: 1;
  }

  .dialog .dialog-header .title {
    font-size: 12pt;
    line-height: 28px;
  }

  .dialog .dialog-header .title .important {
    font-weight: bold;
  }

  .dialog .dialog-header .close::after {
    position: absolute;
    right: 5px;
    content: "b";
    font-family: "aamc-icons";
  }

  .dialog .dialog-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    overflow: hidden;
  }

  .dialog.navigation-item-list .dialog-header .icon::before {
    position: absolute;
    left: 5px;
    content: "l";
    font-family: "aamc-icons";
  }

  .dialog.dialog.navigation-item-list .dialog-content .item-list {
    flex-grow: 1;
    background: #ffffff;
  }

  .item-list .question-status.unseen,
  .item-list .question-status.incomplete {
    color: #ff0000;
  }

  .item-list .flag-cell {
    text-align: center;
  }

  .item-list .flagged-icon::after {
    font-size: 12pt;
    font-family: "aamc-icons";
    content: "f";
    color: #006daa;
  }

  .item-list .sort-asc::after {
    content: "▲";
    color: #ffffff;
    font-size: 9pt;
    position: relative;
    left: 3px;
    top: -6px;
  }

  .item-list .sort-desc::before {
    content: "▼";
    color: #ffffff;
    font-size: 9pt;
    position: relative;
    left: 3px;
    top: 6px;
  }

  .item-list table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
  }

  .item-list table thead .column {
    width: 32%;
    user-select: none;
  }

  .item-list table thead th {
    background-color: #80aee1;
    color: #ffffff;
    border-left: 1px solid #d2d2d2;
  }

  .item-list table thead th:first-child {
    border-left: 1px solid #000000;
  }

  .item-list table tbody {
    color: #000000;
    font-size: 15px;
    line-height: 21px;
  }

  .item-list table tbody td {
    line-height: 21px;
    font-size: 15px;
    padding: 5px;
    border: 1px solid #000000;
  }

  .item-list table tbody tr.even {
    background-color: #ffffff;
  }

  .item-list table tbody tr.odd {
    background-color: #f4f4f4;
  }

  .dialog.periodic-table .dialog-header .icon::before {
    position: absolute;
    left: 5px;
    content: "v";
    font-family: "aamc-icons";
  }

  .dialog.periodic-table .dialog-content .scrollable {
    padding: 0;
    overflow-x: auto;
  }

  .dialog.periodic-table .dialog-content {
    padding: 0 0 20px 0;
  }

  .dialog-content .periodic-table-container {
    flex-grow: 1;
    display: flex;
    background: #ffffff;
  }

  .periodic-table-image {
    width: 1010px;
    height: 690px;
    min-width: 1010px;
    min-height: 690px;
    background: url("../img/periodic-table.png");
  }

  .dialog .dialog-footer {
    display: flex;
    justify-content: space-between;
  }

  .dialog .dialog-footer .close {
    position: absolute;
    right: 15px;
    bottom: 5px;
    font-size: 13pt;
    padding-left: 25px;
    padding-right: 5px;
  }

  .dialog .dialog-footer .close::after {
    position: absolute;
    left: 5px;
    top: 1px;
    content: "b";
    font-family: "aamc-icons";
  }

  .dialog .resize.n {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    cursor: ns-resize;
  }

  .dialog .resize.s {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    cursor: ns-resize;
  }

  .dialog .resize.e {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 2px;
    cursor: ew-resize;
  }

  .dialog .resize.w {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2px;
    cursor: ew-resize;
  }

  .dialog .dialog-footer .resize {
    display: block;
    width: 10px;
    height: 10px;
    margin: 3px;
    background: url("../img/resize.gif");
  }

  .dialog .dialog-footer .resize.sw {
    cursor: sw-resize;
    transform: rotate(90deg);
  }

  .dialog .dialog-footer .resize.se {
    cursor: se-resize;
  }

  .dialog .dialog-content .question-row:hover {
    background: #ffff00 !important;
  }

  .dialog .dialog-content .question-link {
    cursor: pointer;
  }

  .dialog .dialog-content .question-link:hover {
    text-decoration: underline;
  }

  .link-like {
    cursor: pointer;
  }

  .link-like:hover {
    color: #ffff00;
  }

  .underline {
    display: inline-block;
    text-decoration: underline;
  }

  .right {
    float: right;
    display: flex;
    flex-direction: row;
  }

  .left {
    float: left;
    display: flex;
    flex-direction: row;
  }

  .multiple-choice-response {
    display: flex;
    cursor: pointer;

    .choice-content {
      p:first-of-type {
        margin-top: 0;
      }
    }
  }

  .multiple-choice-response .label {
    font-weight: bold;
  }

  .multiple-choice-response .label::after {
    content: ".";
    margin-right: 5px;
  }

  .multiple-choice-response .choice-radio::before {
    margin: 0px 8px 0px 0px;
    border: 1px solid transparent;
    padding: 0.2em;
    font-family: "aamc-icons";
    font-size: 12px;
    content: "\43";
  }

  .multiple-choice-response .choice-radio.selected::before {
    content: "\42";
  }

  .user-annotation.highlight-yellow {
    background-color: #ffff00;
  }

  .user-annotation.highlight-yellow.img {
    background-color: unset;
  }

  .user-annotation.highlight-yellow.img img {
    border: 5px solid #ffff00;
    margin: -5px;
  }

  .user-annotation.strikethrough {
    text-decoration: line-through;
  }

  .user-annotation.strikethrough.img {
    display: inline-block;
    position: relative;
  }

  .user-annotation.strikethrough.img::after {
    position: absolute;
    content: "";
    left: -5%;
    top: 50%;
    right: 5%;
    width: 110%;
    border-top: 1px solid;
    border-color: inherit;
    transform: rotate(-10deg);
  }

  .content-container.review {
    flex-direction: column;
    font-family: Tahoma;
  }

  .review-items {
    width: 100%;
    font-size: 16px;
    border-collapse: collapse;
    margin-bottom: 30px;
  }

  .review-item-cell {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
  }

  .review-item-row .review-item-cell:nth-child(3) {
    border-right: none;
  }

  .review-item-flag {
    display: inline-block;
    width: 30px;
    text-align: center;
    cursor: pointer;
  }

  .review-item-flag::after {
    font-size: 12pt;
    font-family: "aamc-icons";
    content: "g";
    color: #006daa;
  }

  .review-item-flag.flagged::after {
    font-size: 12pt;
    font-family: "aamc-icons";
    content: "f";
    color: #006daa;
  }

  .review-item-status {
    float: right;
    padding-right: 25px;
  }

  .review-item-status .question-status.unseen,
  .review-item-status .question-status.incomplete {
    color: #ff0000;
  }

  .content-container.review .expandable .expandable-header {
    background-color: #80aee1;
    height: 25px;
    font-size: 14pt;
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
  }

  .content-container.review .expandable.items {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .content-container.review .expandable.items .incomplete-unseen {
    float: right;
    font-size: 12pt;
  }

  .content-container.review .expandable.items .expandable-contents {
    flex-grow: 1;
    overflow-x: hidden;
  }

  .review .expandable-header .icon {
    cursor: pointer;
    user-select: none;
  }

  .review .expandable-header .icon::before {
    content: "A";
    font-family: "aamc-icons";
    position: relative;
    top: 1px;
    margin: 0 8px;
  }

  .review .expanded .expandable-header .icon::before {
    content: "z";
  }

  .review .collapsed .expandable-contents {
    display: none;
  }

  .review .review-header {
    padding: 15px 0 10px 0;
    font-size: 18px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    vertical-align: middle;
    color: #353535;
  }

  .review .summary .expandable-contents {
    max-height: 220px;
  }

  .review .summary .expandable-contents p:first-child {
    margin-top: 5px;
  }

  .review .summary .expandable-contents p:last-child {
    margin-bottom: 5px;
  }

  .review .question-link {
    cursor: pointer;
  }

  .review .question-link:hover {
    text-decoration: underline;
  }

  .end-incomplete-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    user-select: none;
  }

  .end-incomplete-section .warning-content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  .end-incomplete-section .warning-content .icon {
    background-image: url("../img/warning.svg");
    background-repeat: no-repeat;
    height: 50px;
    width: 100px;
    margin: 20px 15px 0 5px;
  }

  .end-incomplete-section .action-buttons-wrapper {
    display: flex;
    justify-content: space-around;
    padding-bottom: 20px;
  }

  .end-incomplete-section .action-button {
    border: 1px solid #ffffff;
    margin: 5px;
    padding: 3px 10px;
    cursor: pointer;
  }

  .optional-section-decision {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #006daa;
  }

  .optional-section-decision .decision-container {
    width: 600px;
    height: 400px;
    margin: 150px auto;
    background-color: #e1decc;
    border: 3px solid #000000;
    font-family: Verdana;
    font-size: 16px;
    display: flex;
    flex-direction: column;
  }

  .optional-section-decision .decision-container-header {
    background-color: #006daa;
    border-bottom: 1px solid #ffffff;
    height: 30px;
  }

  .optional-section-decision .decision-message {
    display: flex;
    flex-direction: row;
  }

  .optional-section-decision .decision-message-icon {
    background-image: url("../img//decision.svg");
    background-repeat: no-repeat;
    width: 100px;
    margin: 20px 15px;
  }

  .optional-section-decision .decision-message-content {
    flex-grow: 1;
    margin: 0 20px 10px;
  }

  .optional-section-decision .action-buttons-wrapper {
    display: flex;
    justify-content: space-around;
    padding-bottom: 20px;
  }

  .optional-section-decision .action-button {
    border: 2px solid #000000;
    margin: 5px;
    padding: 5px 20px;
    cursor: pointer;
  }

  .break-screen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: #006daa;
  }

  .break-screen .break-info-container {
    width: 450px;
    margin: 125px auto;
    background-color: #e1decc;
    border: 3px solid #000000;
    font-family: Verdana;
    font-size: 16px;
    display: flex;
    flex-direction: column;
  }

  .break-screen .break-header {
    background-color: #006daa;
    border-bottom: 1px solid #ffffff;
    height: 30px;
    color: #ffffff;
    padding-left: 15px;
  }

  .break-screen .candidate-photo {
    background-color: #ffffff;
    height: 300px;
    width: 420px;
    margin: 15px;
  }

  .break-screen .break-information {
    margin: 0 15px;
    font-family: Verdana;
    font-size: 15px;
  }

  .break-screen .break-information legend {
    font-size: 16px;
  }

  .break-screen .info-line:not(:last-child) {
    margin-bottom: 10px;
  }

  .break-screen .action-button {
    border: 2px solid #000000;
    margin: 15px auto;
    padding: 3px 15px;
    cursor: pointer;
  }

  .timeout-warning-dialog .icon::before {
    content: "r";
    font-family: "aamc-icons";
    position: relative;
    right: 9px;
    font-size: 12pt;
  }

  .timeout-warning-dialog .dialog-content {
    background: #ffffff;
    color: #000000;
    margin: 0 20px 30px;
    padding: 0;
  }

  .timeout-warning {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .timeout-warning .wrapper {
    flex-grow: 1;
    border: 1px solid #000000;
    margin: 20px;
    box-shadow: 0 0 0 10px #ffff00;
  }

  .item-response-required-dialog .content {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .item-response-required-dialog .icon {
    background-image: url("../img/info.svg");
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .item-response-required-dialog .action-buttons {
    display: flex;
    justify-content: space-around;
    padding-bottom: 10px;
  }

  .item-response-required-dialog .action-button {
    cursor: pointer;
    border: 1px solid #ffffff;
    padding: 3px 10px;
  }

  .item-response-required-dialog .message {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
  }

  .content-label {
    font-weight: bold;
    user-select: none;
    padding-bottom: 20px;
  }
}
