.report-container {
  overflow: auto;
  height: 100%;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: #fafafa;

  @keyframes bounceIn {
    0% {
      left: -100px;
    }
    50% {
      left: 50px;
    }
    100% {
      left: -30px;
    }
  }

  .return-button {
    position: absolute;
    color: #ffffff;
    background-color: #000000;
    height: 75px;
    width: 75px;
    top: 0;
    animation: bounceIn 2s;
    animation-fill-mode: forwards;
    z-index: 10;

    span {
      padding-left: 15px;
    }
  }

  @keyframes delayedFade {
    0% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .return-label {
    position: absolute;
    left: 50px;
    top: 0;
    height: 75px;
    line-height: 75px;
    animation: delayedFade 5s;
    animation-fill-mode: forwards;

    &.visible {
      opacity: 1;
    }
  }

  .vertical-stack {
    display: flex;
    flex-direction: column;
  }

  .horizontal-stack {
    display: flex;
    flex-direction: row;
  }

  .even {
    justify-content: space-around;
  }

  .spread {
    justify-content: space-between;
  }

  .nav-button {
    user-select: none;
    cursor: pointer;
  }

  .nav-button:hover {
    text-decoration: underline;
  }

  .report-header {
    background: #402b56;
    color: #ffffff;
    position: relative;
    margin-bottom: 55px;

    &::after {
      content: "";
      position: absolute;
      left: calc(50% - 100px);
      top: 100%;
      width: 0;
      height: 0;
      border-left: 100px solid transparent;
      border-right: 100px solid transparent;
      border-top: 50px solid #402b56;
      clear: both;
    }

    .content {
      padding-bottom: 20px;
      text-align: center;
    }

    .title {
      user-select: none;
      padding: 25px;
    }

    .score-component {
      max-width: 250px;
    }

    .score-component svg {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .detail-card {
    text-align: center;
    vertical-align: middle;
    margin: 10px;
    min-width: 210px;
  }

  .score-curve {
    margin: auto;
  }

  .back-navigation {
    margin-top: -50px;
  }

  .summary {
    margin-bottom: 20px;

    .overall-label {
      user-select: none;
      text-align: center;
    }

    .details-grid {
      margin-left: 25px;
    }

    .detail-card-sub-description {
      font-style: italic;
      color: #808080;
      float: right;
      width: 100%;
    }
  }

  .section-summary {
    td:not(:first-child) {
      text-align: center;
    }

    a {
      cursor: pointer;
    }
  }

  .items-table {
    .centered {
      text-align: center;
    }

    .small-column {
      width: 5%;
    }
  }

  .item-highlight {
    padding: 15px;
    margin-bottom: 15px;

    .header {
      margin: 5px;
    }

    .content {
      border-left: 3px solid #f8b332;
      margin-top: 10px;
      margin-bottom: 20px;
      margin-left: 25px;
      padding-top: 1px;
      padding-bottom: 1px;
    }

    .preview-button {
      float: right;
    }
  }

  .skill-group {
    margin-top: 15px;
    margin-bottom: 15px;

    .graph-container {
      margin-top: auto;
      margin-bottom: auto;
    }

    .overall-label {
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .main-label {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 30px;
      margin-right: 30px;
    }

    .align-self-center {
      align-self: center;
    }
  }

  .skill-breakdown-table {
    .acronym {
      width: 10%;
    }

    .items {
      text-align: center;
      width: 10%;
    }

    .correct {
      text-align: center;
      width: 10%;
    }

    .centered {
      text-align: center;
    }

    .review {
      width: 5%;
    }

    .skill-description-cell {
      white-space: pre-line;
      word-break: break-word;
    }
  }

  .item-table-preview p:last-child:after {
    content: "...";
  }
}

.review-dialog {
  top: 5% !important;
  left: 5% !important;
  bottom: 5% !important;
  right: 5% !important;

  .full-size {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  .outer-container {
    display: flex;
    width: 100%;
    height: calc(100% - 64px);
  }

  .inner-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
  }

  .app-bar {
    background: #402b56;
    position: relative;
  }

  .nav-button-container {
    margin: auto;

    .nav-button {
      border: 1px solid #f8b332;
      color: #f8b332;
      border-radius: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    .first {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .last {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .Mui-disabled {
      border: 1px solid #808080;
      color: #808080;
    }

    .title {
      margin-left: 10px;
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  .item-container {
    margin: 15px;
    border: 1px solid #000000;
    border-radius: 10px;
    overflow: auto;
    overflow-x: hidden;
    height: calc(100% - 30px);
    width: 80%;

    &::-webkit-scrollbar {
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-border-top-right-radius: 10px;
      -webkit-border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-top-right-radius: 10px;
      -webkit-border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      background: #402b56;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }

    &.correct {
      border: 1px solid #8addae;

      &::-webkit-scrollbar-thumb {
        background: #dcffdc;
      }
    }

    &.incorrect {
      border: 1px solid #cb7d75;

      &::-webkit-scrollbar-thumb {
        background: #f8e7e6;
      }
    }

    .content-container {
      padding: 15px;
      overflow-x: auto;
    }
    .rationale-container {
      padding: 15px;
      &.correct {
        border-top: 1px solid #8addae;
        background: #dcffdc;
      }
      &.incorrect {
        border-top: 1px solid #cb7d75;
        background: #f8e7e6;
      }
    }
    .bolded-rationale {
      font-weight: bold;
      p {
        font-weight: bold;
      }
    }
  }

  .info-container {
    width: 20%;
    padding: 5px;
    padding-right: 10px;
    overflow-x: hidden;
    overflow-y: auto;

    .inner {
      margin-right: 15px;
    }

    .answer-table {
      margin-top: 10px;
      margin-bottom: 20px;

      td {
        border: none;
        text-align: center;
      }

      tr {
        height: 64px;

        &.right {
          border: 1px solid #8addae;
          background: #dcffdc;
        }

        &.wrong {
          border: 1px solid #cb7d75;
          background: #f8e7e6;
        }
      }
    }

    .answer-label-cell {
      width: 50px;
    }

    .skill {
      padding: 5px;
      border: 1px solid #000000;
      border-radius: 10px;
      cursor: default;
      user-select: none;
    }
  }
}

.content-container {
  .multiple-choice-responses {
    padding-left: 16px;
  }

  .multiple-choice-response {
    display: flex;
  }

  .multiple-choice-response .label {
    font-weight: bold;
    width: 23px;
  }

  .multiple-choice-response .label::after {
    content: ".";
    margin-right: 5px;
  }

  .user-annotation.highlight-yellow {
    background-color: #ffff00;
  }

  .user-annotation.highlight-yellow.img {
    background-color: unset;
  }

  .user-annotation.highlight-yellow.img img {
    border: 5px solid #ffff00;
    margin: -5px;
  }

  .user-annotation.strikethrough {
    text-decoration: line-through;
  }

  .user-annotation.strikethrough.img {
    display: inline-block;
    position: relative;
  }

  .user-annotation.strikethrough.img::after {
    position: absolute;
    content: "";
    left: -5%;
    top: 50%;
    right: 5%;
    width: 110%;
    border-top: 1px solid;
    border-color: inherit;
    transform: rotate(-10deg);
  }
}
